<script>
    import Carousel from "@centroculturadigital-mx/svelte-carousel";
    import { onMount } from "svelte";
    export let content;
    export let go;
    let classBase = "LightBox"
    
    let classList = `${classBase} ${classBase}--hidden`
    
    let shouldOpen = false
    $: contents = Array.isArray(content) ? content : [content]
    $: open(go)
    onMount(()=>{
        shouldOpen = true
        
         
        
        
        document.addEventListener('keydown', event => {
            switch(event.keyCode) {
                case 27:
                    close();
                    break;
            }
            
        });
    })
    const open = (goIndex) => {
        if(( goIndex === 0 || goIndex > 0) && shouldOpen ) {
            classList = classBase
        }
    }
    const close = () => {
        go=null
        classList = `${classBase} ${classBase}--hidden`
    }
 
</script>


<style>
    .LightBox {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        opacity: 1;
        transition: opacity 1s;
    }
    .LightBox header {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    
    .LightBox :global(.carousel)
    /* ,
    .LightBox :global(.slides)  */
    {
        width: 100vw;
        height: 100vh;
    }
    .LightBox :global(.slides > * > *) {
        width: 100vw !important;
        /* height: 70vh !important; */
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .LightBox :global(.carousel > ul) {
        bottom: 0;
    }
    .LightBox :global(.carousel img) {
        object-fit: contain;
        width: 100%;
    }
    .Lightbox__Overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,0.7);
        
    }
    .LightBox--hidden {
        opacity: 0;
        z-index: -1;
    }
    
</style>



<div class={classList}>
    <header>
        <button on:click={close}>
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsSAAALEgHS3X78AAABB0lEQVRIx+1V2wqCQBA1fRXxlpdvSeibE+oLgjLzQj9kszALh8VkdzN6qIEDus6eMzO7MzrO3yxtA1jTd9Zcy28voxG2IxwIBb97M75yLSPUhErhWBQQ5BPhRkhmRORzSLiy78lEoGBysfFOiIEYyRv26QiljgDWNWFyVUQlHwip6ZnIKGMQ6QkBwSe0vDYCuWd64CjSM+GFcObnB2FrS66KBEw+MUQG0bvkuNmHyGUmwVrRh1DzFjLpldtlTd5AzSOOvIPbZVwqd4Z8hANVb1cLmbi6oyKDDh2UqygjjaB0oilzk06uoUPThVERQ8cfTQQqni2lxrDLmXyvOyo+Oq6/8sP5cXsCaq5BhBP4KlEAAAAASUVORK5CYII=" alt="">
        </button>
    </header>
    
    <div class="Lightbox__Overlay" on:click={close}></div>
    <Carousel perPage={({perParge:1})} go={go}>
            
        {#each contents as content,i ("content_"+i)}
            {#if content.type=="image"|| !content.type }
                <img src={content.full} alt={content.title}>
            {/if}
        {/each}

    </Carousel>
    

</div>