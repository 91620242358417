<script>
  import { url } from "@sveltech/routify";
  import PageTransitions from "./components/PageTransitions.svelte";
  import Header from "./components/Header.svelte";
  import Footer from "./components/Footer.svelte";

  let year = new Date();
  let date = year.getFullYear();
</script>

<style>
  .container {
    margin: 0 auto;
    max-width: 1280px;
  }

  .copyright {
    color: #333;
    background: #fff;
    padding: 1em;
    text-align: center;
    font-size: 14px;
  }

  .copyright img {
    width: 18px;
    margin-bottom: -2px;
  }

  .copyright a {
    text-decoration: none;
    color: #333;
  }
</style>

<div class="container">
  <Header />
  <slot decorator={PageTransitions} />
</div>

<Footer />

<div class="copyright">
  <p>
    IKPH Inter-Kontakt Projekt und Handel GmbH ©{date} | Crafted in
    <a href={$url('https://varvanin.eu')}>
      <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAmCAYAAACGeMg8AAAACXBIWXMAAAsSAAALEgHS3X78AAAD3klEQVRYw+2ZS2xNURSG79WnRzXEoxKJRA2UqBYlUuo58AwSJGIgYYAYCIIqAwaohI4I8RpIExGlngkmJERJVFXRXm0FA6EafWi1qlr/kv/KcXLP3vuce5t20JV8uX2cs/dZ+6z1r7X39fl6reeaH8SDvgrk/7G8ViwOJID+DsSF+UzxirGFfqFuGgsugNvgJrkBrpNroBAcAylgIDgMOkEAVIIqflaDd+AkGO7RiWhwnmNV28av4t+KQYz1JlnlH3woE96CLHAJdGiuvWh5g25sEfioGfszn/0/RwIuHGkAuWAhf3a6TpwsYPi5sT7gNGhVjP0bHOGb+2cxLh1pZ5hNBSWaa9+DVJeOjAKP+LBO434CGfa37daRTubAepCtmfAXWMNVNrVNoFYz/wOKgS9cR+S1nwWzGauqa4+DJEMnJMyvcAFUi7MZRIVypNilI8J9sJyqprpOFmmmoSPTQZlmPFGu5FAiIp7tBi/BG0PKKdcSzxtAm2JiUcQtdoVxsEOgyUAJY1SDpIF0fupItYRLBnVdNXkhV1Flg8BdTc41g8WhwioSlkS5VD1ADWuDqqasNqgdpWBoV7Y4KzU1RcThAEhUVPJz4KdG9nN1rc8QKlAWE1PHDJIJxpBnmtUsAhMd5h8HHmve6lcwTSXlEm95oAU0cmWD1JM6B2rZl0lxzOGqOT1IE2tKqPjeBr4ZqGSiTru/e5Bfq66fAnNZcVUti+TSCNv80sVe1ixCG2uHUvliKKdeHZEHvAeWgKsGNSDTNv888Fpz3wd26H6dIxVhOCJ8YXis0yRsC2uKtb3INawdA3SKEwlHOjiZCMZTg6Qfz7lTmOSq7UA9BSbKxJHqMB0J7g8Ogr2at1JPuQ4mua5BfGi6QRNP91gmafBAM1sXyZP5lEpVPTgBRoN8gyTfwa22kUl7sJShMccDkrBT+HalptzSrHIZ5bpUc10N2yG/rxtMEnkrE9vpAevYjrRqHBFZHtydpzKzNHnXbpBzzSygsW4nT2OFzmCYTLYxyfIZJN3WMU9g9ZVQPWNwOGGy73B19HIUPOcJSSAEFaTcQnBvIsXsFSnh0dEKEk7HkMdjJ2OLjZD8WsMmn6csRR7HaGTL42av/1dpKiPoSDAsNrKmeAkvOUUZ6TY3IlHZQ+0/5CRymUa9nJrQ7TwW7XZHhDvMkxcu76uhkPi9OBLoAkekuu/kIYWb+6RnG+ZF88WRJ13gSCcPonexJpjes9Z+FOrGktmcFfKALEhBGOSxLsnGaRXYx7Ykm71dju33/WCBSbuu+34kQfN9hFv6WuQzmocGOqJ8vdZrPcf+AKYNgfyeEDunAAAAAElFTkSuQmCC" alt="Tworzenie i projektowanie stron internetowych" />
      - design studio
    </a>
  </p>
</div>

