<script>

</script>

<style>
  footer {
    width: 100%;
    color: #333;
    padding: 130px 0 190px;
    text-align: center;
    background-image: url(../../bg-footer.png);
    background-repeat: no-repeat;
    background-size: cover;
  }

  footer h5 {
    color: #009ee0;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 40px;
  }

  footer h2 {
    font-size: 60px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  footer span {
    font-size: 24px;
    font-weight: 600;
    display: block;
    margin-bottom: 17px;
  }

  @media (max-width: 650px) {
    footer {
      padding: 30px 0 90px;
    }
    footer h2 {
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 30px;
    }
    footer span {
      font-size: 14px;
      font-weight: 400;
    }
  }
</style>

<footer>
  <div class="footer-text">
    <h5>LASS UNS ZUSAMMEN ARBEITEN</h5>
    <h2>+49 30 984 34 329</h2>
    <span>Altonaer Str 59-61, 13581 Berlin</span>
    <span>email:info@ikph.de</span>
  </div>
</footer>
